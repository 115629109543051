import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Form } from 'semantic-ui-react';

import { actionTypes, login } from 'actions/user';
import { loadingStateSelector } from 'reducers/ui';

import { RouterLinkV2 } from 'components/ui/Link';
import { AnalyticsAwareButton, ButtonAccent } from 'components/ui/button';
import { TextInput } from 'components/ui/inputs/TextInput';
import { ButtonLineLayout } from 'components/ui/layout/Page';

import * as svars from 'assets/style/variables';

import {
  LoginHeaderMessageContainer,
  LoginMessageContainer,
} from './LoginLayout';
import PasswordFormField from './PasswordFormField';

function LoginPage() {
  const dispatch = useDispatch();
  const [requestError, setRequestError] = useState(false);

  const loading = useSelector(
    loadingStateSelector([actionTypes.LOGIN_REQUEST])
  );
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const renewPasswordParameters = username
    ? `?user=${encodeURIComponent(username)}`
    : '';
  const onSetUsername = useCallback(
    (event, data) => setUsername(data.value),
    [setUsername]
  );
  const onSetPassword = useCallback(
    (event, data) => setPassword(data.value),
    [setPassword]
  );
  const logUserIn = useCallback(
    (...params) => {
      if (requestError) setRequestError(false);
      dispatch(login(...params)).catch(() => setRequestError(true));
    },
    [requestError]
  );
  return (
    <>
      <LoginHeaderMessageContainer>
        <Trans id="log-in-to-your-account" />
      </LoginHeaderMessageContainer>
      <Form style={{ width: '100%' }}>
        <Form.Field>
          <Trans
            id="username"
            render={(translation) => (
              <TextInput
                type="text"
                autoComplete="username"
                id="Username"
                icon="user"
                iconPosition="left"
                onChange={onSetUsername}
                placeholder={i18n._(translation)}
                data-testid="bo-login-username-input"
              />
            )}
          />
        </Form.Field>
        <Trans
          id="password"
          render={(translation) => (
            <PasswordFormField
              id="Password"
              onChange={onSetPassword}
              placeholder={i18n._(translation)}
              data-testid="bo-login-password-input"
            />
          )}
        />
        <ButtonLineLayout
          style={{
            justifyContent: 'space-between',
          }}
        >
          <RouterLinkV2
            base="true"
            style={{ paddingRight: svars.spaceLarge }}
            to={`/renew-password${renewPasswordParameters}`}
            data-testid="bo-reset-password-url"
          >
            <Trans id="forgotten-password?" />
          </RouterLinkV2>
          <AnalyticsAwareButton
            gaCategory="Customer"
            gaAction="Login"
            inputComponent={ButtonAccent}
            type="submit"
            onClick={() => !loading && logUserIn(username, password)}
            disabled={!username || !password}
            loading={loading}
            data-testid="bo-login-button"
          >
            <Trans id="log in" />
          </AnalyticsAwareButton>
        </ButtonLineLayout>
        {requestError && (
          <LoginMessageContainer error={requestError}>
            <Trans id="log-in-did-not-work" />
          </LoginMessageContainer>
        )}
      </Form>
    </>
  );
}

LoginPage.propTypes = {};

export default LoginPage;
