import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { Trans, msg } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Form, List, Loader } from 'semantic-ui-react';

import { toggleSupportModal } from 'actions/ui';
import { api } from 'actions/utils';

import Link, { RouterLinkV2 } from 'components/ui/Link';
import { AnalyticsAwareButton, ButtonAccent } from 'components/ui/button';
import { ButtonLineLayout } from 'components/ui/layout/Page';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import {
  LoginHeaderMessageContainer,
  LoginMessageContainer,
} from './LoginLayout';
import PasswordFormField from './PasswordFormField';
import RenewPasswordPage from './RenewPasswordPage';

const digitsAndLettersRe = /(?=.*\d)(?=.*[a-zA-Z]).*/;
const uppercaseRe = /(?=.*[A-Z]).*/;
const passwordRules = [
  {
    key: 'pwd-contains-letters-digits',
    test: (password) => digitsAndLettersRe.test(password),
    message: msg({ id: 'update-password.password-contains-letter-and-number' }),
  },
  {
    key: 'pwd-contains-uppercase',
    test: (password) => uppercaseRe.test(password),
    message: msg({ id: 'update-password.password-contains-uppercase-letter' }),
  },
  {
    key: 'pwd-length',
    test: (password) => password.length >= 8,
    message: msg({ id: 'update-password.password-length-8-characters' }),
  },
  {
    key: 'pwd-confirm',
    test: (password, passwordConfirm) =>
      passwordConfirm && password === passwordConfirm,
    message: msg({ id: 'update-password.password-confirm-equal' }),
  },
];

function RequestMessage({ onToggleSupportModal, requestError, username }) {
  return (
    <LoginMessageContainer error={requestError}>
      {(requestError && (
        <Trans>
          Nous ne pouvons mettre à jour votre mot de passe, veuillez{' '}
          <RouterLinkV2
            base="true"
            to={
              username
                ? `/renew-password?user=${encodeURIComponent(username)}`
                : '/renew-password'
            }
          >
            réessayer
          </RouterLinkV2>{' '}
          ou{' '}
          <Link base="true" onClick={onToggleSupportModal}>
            contacter le support.
          </Link>
        </Trans>
      )) ||
        (requestError === false && (
          <Trans>
            Votre mot de passe est à jour,{' '}
            <RouterLinkV2 base="true" to="/login">
              connectez-vous
            </RouterLinkV2>{' '}
            dès maintenant.
          </Trans>
        )) ||
        null}
    </LoginMessageContainer>
  );
}

RequestMessage.propTypes = {
  onToggleSupportModal: PropTypes.func.isRequired,
  requestError: PropTypes.bool.isRequired,
  username: PropTypes.string,
};

RequestMessage.defaultProps = {
  username: undefined,
};

async function checkIfTokenExpired(
  searchParams,
  setRequestError,
  setIsLinkExpired
) {
  const token = searchParams.get('token');
  setRequestError(undefined);
  let isTokenExpired = true;

  try {
    await api.post('/user/check-token', {
      token,
      new_account: searchParams.get('new_account') === 'true',
    });
    isTokenExpired = false;
  } catch (error) {
    isTokenExpired = true;
  }
  setIsLinkExpired(isTokenExpired);
}

function UpdatePasswordPage({ language, onToggleSupportModal }) {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(undefined);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [username, setUsername] = useState();
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  const requestPasswordUpdate = useCallback(
    async (newPassword) => {
      const token = searchParams.get('token');
      setUsername(searchParams.get('user'));
      setRequestError(undefined);
      setLoading(true);
      let newRequestError = false;
      let newPasswordUpdated = true;
      let response;
      try {
        response = await api.post('/user/update-password', {
          token,
          password: newPassword,
          locale: language,
          new_account: searchParams.get('new_account') === 'true',
        });
        if (response?.data?.message === 'ko') {
          newRequestError = true;
          newPasswordUpdated = false;
        }
      } catch (error) {
        newRequestError = true;
        newPasswordUpdated = false;
      }
      setLoading(false);
      setRequestError(newRequestError);
      setPasswordUpdated(newPasswordUpdated);
    },
    [searchParams, language]
  );

  useEffect(() => {
    const initializePage = async () => {
      const newAccountParam = searchParams.get('new_account') === 'true';
      setIsNewAccount(newAccountParam);
      await checkIfTokenExpired(
        searchParams,
        setRequestError,
        setIsLinkExpired
      );
      setIsInitializing(false);
    };
    initializePage();
  }, []);

  const checkedRules = [];
  let rulesCheck = true;
  passwordRules.forEach(({ key, test, message }) => {
    const testCheck = password && test(password, passwordConfirm);
    if (!testCheck) {
      rulesCheck = false;
    }
    checkedRules.push({ key, message, check: testCheck });
  });
  const onPasswordChange = useCallback(
    (event, { value }) => setPassword(value),
    [setPassword]
  );
  const onPasswordConfirmChange = useCallback(
    (event, { value }) => setPasswordConfirm(value),
    [setPasswordConfirm]
  );

  if (isInitializing) {
    return <Loader active inline="centered" large />;
  }

  return isLinkExpired ? (
    <RenewPasswordPage isLinkExpired />
  ) : (
    <>
      <LoginHeaderMessageContainer>
        {isNewAccount === true || isNewAccount === null ? (
          <Trans id="password-update.set-first-password" />
        ) : (
          <Trans id="password-update.set-and-confirm-new-password" />
        )}
      </LoginHeaderMessageContainer>
      <Form style={{ width: '100%' }}>
        <Trans
          id="password"
          render={(translation) => (
            <PasswordFormField
              id="newPassword"
              onChange={onPasswordChange}
              placeholder={i18n._(translation)}
              disabled={passwordUpdated}
            />
          )}
        />
        <Trans
          id="password-confirmation"
          render={(translation) => (
            <PasswordFormField
              id="newPasswordConfirm"
              onChange={onPasswordConfirmChange}
              placeholder={i18n._(translation)}
              disabled={passwordUpdated}
            />
          )}
        />
        <ButtonLineLayout
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <AnalyticsAwareButton
            gaCategory="Customer"
            gaAction="Update password"
            // gaLabel={username}
            inputComponent={ButtonAccent}
            type="submit"
            onClick={() => !loading && requestPasswordUpdate(password)}
            disabled={
              !(
                password === passwordConfirm &&
                rulesCheck &&
                !requestError &&
                !passwordUpdated
              )
            }
            loading={loading}
          >
            <Trans id="update-password" />
          </AnalyticsAwareButton>
        </ButtonLineLayout>
        <RequestMessage
          onToggleSupportModal={onToggleSupportModal}
          requestError={requestError}
          username={username}
        />
      </Form>
      <List
        style={{
          paddingTop: svars.spaceNormal,
          opacity: passwordUpdated || requestError ? 0.3 : 1,
        }}
      >
        <List.Header style={{ paddingBottom: svars.spaceSmall }}>
          <Trans id="update-password.password-should" /> :
        </List.Header>
        {checkedRules.map(({ key, check, message }) => (
          <List.Item
            key={key}
            style={{
              color: check ? svars.colorSuccess : svars.colorWarning,
            }}
          >
            <List.Icon name={check ? 'check circle' : 'warning circle'} />
            <Trans id={message} />
          </List.Item>
        ))}
      </List>
    </>
  );
}

UpdatePasswordPage.propTypes = {
  language: commonPropTypes.language.isRequired,
  onToggleSupportModal: PropTypes.func.isRequired,
};

UpdatePasswordPage.defaultProps = {};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  language: state.locale.language,
});

export default connect(mapStateToProps, (dispatch) => ({
  onToggleSupportModal: () => dispatch(toggleSupportModal()),
}))(UpdatePasswordPage);
