import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { toggleSupportModal } from 'actions/ui';
import { api } from 'actions/utils';

import Link from 'components/ui/Link';
import { AnalyticsAwareButton, ButtonAccent } from 'components/ui/button';
import { TextInput } from 'components/ui/inputs/TextInput';
import { ButtonLineLayout } from 'components/ui/layout/Page';

import commonPropTypes from 'utils/commonPropTypes';
import 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import {
  LoginHeaderMessageContainer,
  LoginMessageContainer,
} from './LoginLayout';

function RequestMessage({ error, onToggleSupportModal }) {
  return (
    <LoginMessageContainer error={error}>
      {(error && (
        <Trans>
          Une erreur est survenue lors de l&aposenvoi de l&aposemail. Veuillez
          réessayer plus tard. Si l&aposerreur persiste, veuillez{' '}
          <Link base="true" onClick={onToggleSupportModal}>
            contacter le support.
          </Link>
        </Trans>
      )) ||
        (error === false && (
          <Trans>
            Si votre adresse email est enregistrée chez nous, vous devriez
            recevoir un message avec des instructions supplémentaires sous une
            minute. Veuillez vérifier votre boîte de réception et votre dossier
            de spam. Si vous ne le recevez pas veuillez{' '}
            <Link base="true" onClick={onToggleSupportModal}>
              contacter le support.
            </Link>
          </Trans>
        )) ||
        null}
    </LoginMessageContainer>
  );
}

RequestMessage.propTypes = {
  onToggleSupportModal: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

function RenewPasswordPage({ onToggleSupportModal, language, isLinkExpired }) {
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState(() => {
    const userParam = searchParams.get('user');
    return userParam ? decodeURIComponent(userParam) : '';
  });
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(undefined);
  const [tokenSent, setTokenSent] = useState(false);

  const onChangeUsername = useCallback(
    (event, data) => setUsername(data.value),
    []
  );
  const requestPasswordRenewal = useCallback(
    async (newUsername) => {
      setLoading(true);
      setRequestError(null);
      let newRequestError = false;
      let newTokenSent = newUsername;
      try {
        await api.post('/user/renew-password', {
          username: newUsername,
          locale: language,
        });
      } catch (error) {
        newRequestError = true;
        newTokenSent = false;
      }
      setRequestError(newRequestError);
      setTokenSent(newTokenSent);
      setLoading(false);
    },
    [username, setLoading, setRequestError, setTokenSent]
  );

  return (
    <>
      <LoginHeaderMessageContainer>
        {isLinkExpired ? (
          <Trans id="password-update.expired-link" />
        ) : (
          <Trans id="renew-password.enter-email-related-to-account" />
        )}
      </LoginHeaderMessageContainer>
      <Form style={{ width: '100%' }}>
        <Form.Field>
          <Trans
            id="email-or-username"
            render={(translation) => (
              <TextInput
                id="Username"
                icon="user"
                iconPosition="left"
                onChange={onChangeUsername}
                placeholder={i18n._(translation)}
                value={username}
              />
            )}
          />
        </Form.Field>
        <ButtonLineLayout>
          <AnalyticsAwareButton
            gaCategory="Customer"
            gaAction="Request password renewal"
            gaLabel={username}
            inputComponent={ButtonAccent}
            type="submit"
            onClick={() => !loading && requestPasswordRenewal(username)}
            disabled={!username || tokenSent === username}
            loading={loading}
          >
            {isLinkExpired ? (
              <Trans
                render={capitalizedTranslation}
                id="password-update.resend-link"
              />
            ) : (
              <Trans render={capitalizedTranslation} id="send-reset-link" />
            )}
          </AnalyticsAwareButton>
        </ButtonLineLayout>
        <RequestMessage
          onToggleSupportModal={onToggleSupportModal}
          error={requestError}
        />
      </Form>
    </>
  );
}

RenewPasswordPage.propTypes = {
  language: commonPropTypes.language.isRequired,
  onToggleSupportModal: PropTypes.func.isRequired,
  isLinkExpired: PropTypes.bool,
};

RenewPasswordPage.defaultProps = { isLinkExpired: false };

export default connect(
  (state) => ({
    language: state.locale.language,
  }),
  (dispatch) => ({
    onToggleSupportModal: () => dispatch(toggleSupportModal()),
  })
)(RenewPasswordPage);
